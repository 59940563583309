html {
	box-sizing: border-box;
	font-size: 62.5%;
	scroll-behavior: smooth;
	/* scrollbar-width: none; */
}

* {
  font-family: 'Archia';
}

.App {
  margin: 0 auto;
  max-width: 100rem;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.switches {
  display: flex;
  flex-direction: column;
}

.switches .switch {
  background: none;
  appearance: none;
  border: 0;
  padding: 2.5rem 2rem;
  margin: 1rem 0;
  border-radius: .5rem;
  transition: all .25s;
}

.rainfall:hover {
  background-color: #529bcc6c;
}

.rainfall:active {
  background-color: #529CCC;
}

.literacy:hover {
  background-color: #58b66877;
}

.literacy:active {
  background-color: #58B668;
}

.population:hover {
  background-color: #7575756b;
}

.population:active {
  background-color: #757575;
}

.sex-ratio:hover {
  background-color: #938fc371;
}

.sex-ratio:active {
  background-color: #938FC3;
}

.svg-parent {
  margin: 5rem;
}

/* Table */
.table {
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 50rem;
  overflow-y: scroll;
  scrollbar-width: none;
}

.table::-webkit-scrollbar {
  display: none;
}

.table-row {
  display: flex;
}

.heading {
  font-weight: bolder;
  /* position: fixed; */
}

.table .district-cell {
  background-color: rgba(0, 0, 0, .1);
  width: 20rem;
  margin: .2rem;
  padding: .5rem 1rem;
  border-radius: .4rem;
  font-size: 1.2rem;
}
.table .values-cell {
  background-color: rgba(0, 0, 0, .1);
  width: 7rem;
  margin: .2rem;
  padding: .5rem 1rem;
  border-radius: .4rem;
  font-size: 1.2rem;
}